.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow:  0 1px 0 #fff;
    opacity: .5;
  
 
  
    // Opinionated: add "hand" cursor to non-disabled .close elements
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }
  
  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  
  // stylelint-disable property-no-vendor-prefix, selector-no-qualifying-type
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  // stylelint-enable