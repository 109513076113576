/*
Template Name: Wrapkit
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Wrapkit
Author: wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Gradiant*/
/*============================================================== 
 This scss/css we made it from our predefine componenet 
 we just copy that css and paste here you can also do that
 ============================================================== */
/*******************
Header 2
*******************/
.h2-nav {
  width: 100%;
  z-index: 1;
  left: 0px;
  padding: 25px 0px; }
  .h2-nav .navbar-nav .nav-item {
    margin: 0 15px; }
    .h2-nav .navbar-nav .nav-item .nav-link {
      padding: 12px 0px;
      color: #ffffff;
      font-weight: 400;
      opacity: 1; }
      .h2-nav .navbar-nav .nav-item .nav-link:hover {
        opacity: 0.5; }
    .h2-nav .navbar-nav .nav-item .btn {
      opacity: 0.5; }
      .h2-nav .navbar-nav .nav-item .btn:hover {
        opacity: 1; }

.fixed-header {
  background: #00b4ff; }
  .fixed-header .h2-nav {
    padding: 15px 0px; }

@media (max-width: 767px) {
  .h2-nav .navbar-collapse {
    background: #263238;
    padding: 15px 15px 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
    box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1); } }

/*-------------------*/
/*Banner1 Style Start*/
/*-------------------*/
#banner1 {
  padding-top: 200px;
  padding-bottom: 200px;
  background-size: cover; }
  #banner1 h2 {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: -1px; }
  #banner1 input {
    line-height: 62px;
    padding: 0;
    margin: 0;
    border: none;
    display: inline-block;
    vertical-align: top; }
  #banner1 input[type="text"] {
    color: #8d97ad;
    border-radius: 36px 0 0 36px;
    text-indent: 35px;
    padding-right: 15px;
    width: 61%;
    font-weight: 300;
    -webkit-box-shadow: -5px 0 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: -5px 0 30px 0 rgba(0, 0, 0, 0.05);
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis; }
  #banner1 input[type="submit"] {
    border-radius: 0 36px 36px 0;
    width: auto;
    padding: 0 30px;
    cursor: pointer;
    margin-left: -4px;
    -webkit-box-shadow: 5px 0 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 5px 0 30px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    #banner1 input[type="submit"]:hover {
      opacity: 0.7; }

@media (max-width: 1100px) {
  #banner1 h2 {
    font-size: 40px;
    line-height: 55px; }
  #banner1 input[type="text"] {
    width: 59%; }
  #banner1 input[type="submit"] {
    padding: 0 20px; } }

@media (max-width: 992px) {
  .banner {
    height: auto !important;
    padding: 130px 0; }
  #banner1 {
    background-position: right -6em center;
    background-size: cover;
    padding: 130px 0 200px 0; }
    #banner1 h2 {
      font-size: 30px;
      line-height: 35px; } }

@media (max-width: 767px) {
  .banner {
    padding: 30px 0; }
  #banner1 {
    background-size: cover;
    background-position: right center;
    padding: 120px 0 60px; }
    #banner1 input[type="submit"] {
      padding: 0 30px; }
    #banner1 img {
      margin-top: 25px; } }

@media (max-width: 428px) {
  #banner1 input {
    font-size: 14px;
    line-height: 50px; }
  #banner1 input[type="text"] {
    border-radius: 25px 0 0 25px;
    text-indent: 20px; }
  #banner1 input[type="submit"] {
    border-radius: 0 25px 25px 0;
    padding: 0 15px; } }

@media (max-width: 320px) {
  #banner1 input[type="submit"] {
    padding: 0 10px; } }

/*******************
Feature 11
*******************/
.wrap-feature11-box {
  margin-top: -110px; }
  .wrap-feature11-box .card-body {
    padding: 40px; }
  .wrap-feature11-box .icon-space {
    margin: -80px 0 20px; }
    .wrap-feature11-box .icon-space .icon-round {
      font-size: 45px;
      color: #ffffff; }

/*******************
Feature 41
*******************/
.wrap-feature41-box .f41-tab {
  display: block; }
  .wrap-feature41-box .f41-tab li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding: 25px; }
    .wrap-feature41-box .f41-tab li a .t-icon {
      margin-right: 25px;
      color: #8d97ad; }
    .wrap-feature41-box .f41-tab li a h5 {
      font-weight: 500; }
    .wrap-feature41-box .f41-tab li a h6 {
      font-size: 14px;
      margin-bottom: 0px; }
    .wrap-feature41-box .f41-tab li a.active, .wrap-feature41-box .f41-tab li a:hover {
      background: #ffffff;
      -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
      box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1); }
      .wrap-feature41-box .f41-tab li a.active .t-icon, .wrap-feature41-box .f41-tab li a:hover .t-icon {
        background: #00b4ff;
        background: -webkit-linear-gradient(legacy-direction(to right), #00b4ff 0%, #00b4ff 100%);
        background: -webkit-gradient(linear, left top, right top, from(#00b4ff), to(#00b4ff));
        background: -webkit-linear-gradient(left, #00b4ff 0%, #00b4ff 100%);
        background: -o-linear-gradient(left, #00b4ff 0%, #00b4ff 100%);
        background: linear-gradient(to right, #00b4ff 0%, #00b4ff 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent; }

/*******************
Feature 8
*******************/
.feature8 .list-block li {
  font-weight: 400;
  color: #263238; }

/*******************
Pricing 5
*******************/
.pricing5 .general-listing {
  margin: 90px 0 60px 0; }
  .pricing5 .general-listing.only-li li {
    padding: 20px 0;
    display: block; }

.pricing5 .card {
  background-size: 100% !important; }

.pricing5 .yearly {
  display: none; }

.pricing5 .pricing-box {
  margin-top: 120px; }
  .pricing5 .pricing-box .middle-box {
    margin-top: -75px; }
    .pricing5 .pricing-box .middle-box .general-listing {
      margin-top: 60px; }

@media (max-width: 767px) {
  .pricing5 .pricing-box {
    margin-top: 40px; }
    .pricing5 .pricing-box .middle-box {
      margin-top: 0px; } }

.client2 {
  padding: 0px 0 80px; }
  .client2 .client-box {
    margin: 0px; }
    .client2 .client-box [class*="col-"] {
      padding-top: 10px;
      padding-bottom: 10px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }

/*******************
Testimonial 3
*******************/
.testimonial3 .testi3 .card-body {
  padding: 40px; }

.testimonial3 .testi3 h6 {
  line-height: 26px; }

.testimonial3 .testi3 .thumb-img img {
  width: 60px; }

.testimonial3 .testi3 .customer {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500; }

/*******************
Call to action
*******************/
.feature48 {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.feature48 .wrap-feature48-box {
  padding: 10% 0; }
